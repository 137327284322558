<template>
  <div class="mb-16 md:mb-6">
    <h3 class="text-18 font-bold text-gray-700 mr-2">
      Deals & Rewards
    </h3>
    <div class="mt-2">
      <marketplace-deal class="w-full" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Deals',
};
</script>
  